<template>
  <v-container>
    <div>
      <div>
        <div class="headline">
          Delete {{ camelCaseToTitleCase(collectionName) }}?
        </div>
        <span>
          Are you sure you would like to delete this
          {{ camelCaseToTitleCase(collectionName) }}?
        </span>
        <div class="button-container">
          <v-spacer />
          <v-btn
            :id="`${id}-base-deletion-button-cancel`"
            class="btn-secondaryaction"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :id="`${id}-base-deletion-button-delete-target`"
            class="delete-target"
            flat
            @click="deleteTarget"
          >
            {{ `Delete ${camelCaseToTitleCase(collectionName)}` }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { camelCaseToTitleCase, yToIe } from '@/utils/string'

export default {
  name: 'BaseDeletion',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    close: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    specialIdentifier: {
      type: String,
      default: '',
    },
    collectionName: {
      type: String,
      default: () => {
        return ''
      },
    },
    stateActionOverride: {
      type: String,
      default: () => {
        return ''
      },
    },
    id: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
  },
  methods: {
    deleteTarget() {
      if (this.action) {
        this.action(
          this.row[this.specialIdentifier] ||
            this.row[`${this.collectionName}Id`]
        ).then(() => {
          this.close()
          this.row.deleteMe(this.row)
          this.$store.dispatch('app/showAlert', {
            message: `${camelCaseToTitleCase(
              this.collectionName
            )} successfully deleted.`,
          })
        })
      } else {
        this.$store
          .dispatch(
            `${yToIe(
              this.collectionName
            )}s/delete${this.collectionName.replace(/^./, (str) =>
              str.toUpperCase()
            )}`,
            this.row[this.specialIdentifier] ||
              this.row[`${this.collectionName}Id`]
          )
          .then(() => {
            this.close()
            this.row.deleteMe(this.row)
            this.$store.dispatch('app/showAlert', {
              message: `${camelCaseToTitleCase(
                this.collectionName
              )} successfully deleted.`,
            })
          })
      }
    },
    cancel() {
      this.close()
    },
    camelCaseToTitleCase,
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 10px;
  margin-left: -10px;
}

.delete-target {
  color: $white;
  background-color: $red;
}
</style>
