import { render, staticRenderFns } from "./BaseActionsRequiresWrapper.vue?vue&type=template&id=b6ff8e80&scoped=true"
import script from "./BaseActionsRequiresWrapper.vue?vue&type=script&lang=js"
export * from "./BaseActionsRequiresWrapper.vue?vue&type=script&lang=js"
import style0 from "./BaseActionsRequiresWrapper.vue?vue&type=style&index=0&id=b6ff8e80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6ff8e80",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VLayout,VList,VListTile,VListTileTitle,VMenu,VTooltip})
