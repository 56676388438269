<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Industries</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import IndustryDetail from '@/components/IndustryDetail.vue'
import industries from '@/services/industries'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'industry'
)

const columns = [
  {
    _t_id: 'z614fbb01',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: 'fe867292',
    prop: 'industryId',
    text: 'Industry ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'industryId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: 'fe867508',
    prop: 'label',
    text: 'Industry',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'label',
    detail: false,
  },
  {
    _t_id: '6ff4b45b',
    prop: 'parentIndustry/label',
    text: 'Parent Industry',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'parentIndustry/label',
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        addNewEnabled: true,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        enableExport: true,
        exportServiceAction: (params) => industries.exportIndustries(params),
        exportName: 'industries.csv',
        externalShareFilters: this.receiveFilters,
        externalShareSorts: this.receiveSorts,
        addNewHandler: () => this.$router.push({ name: 'industries.add' }),
        detailKeyId: 'industryId',
        tableId: 'industries_tv_view',
        detail: IndustryDetail,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Industries',
        action: (params) => industries.getIndustries(params),
      },
    }
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
  },
}
</script>
