<template>
  <v-layout row>
    <div
      v-for="(item, idx) in menu
        .filter((m) => m.quickAccess)
        .filter((m) => (m.condition ? m.condition(row) : true))"
      :key="`item-${idx}-${item && item.title() ? item.title() : ''}`"
      class="with-margin"
      style="max-height: 22px; margin-left: 10px"
    >
      <v-tooltip top>
        <template #activator="action">
          <span
            class="item-space"
            style="max-height: 22px"
            v-on="action.on"
            @click.stop="item.click"
          >
            <CRIcon
              v-if="item.icon && item.click"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :color="(item.color && item.color()) || 'primary'"
              :width="22"
              :height="22"
              :view-box="item.viewBox"
            >
              {{ item.icon() }}
            </CRIcon>
            <img
              v-if="item.svg && item.click"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :src="item.svg"
              class="action-svg"
              @click="item.click"
            />
            <router-link
              v-if="item.to"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :to="item.to"
              class="action-link"
              target="_blank"
            >
              <CRIcon
                v-if="item.icon"
                :color="(item.color && item.color()) || 'primary'"
                :view-box="item.viewBox ? item.viewBox : '0 0 24 24'"
                :width="24"
                :height="24"
              >
                {{ item.icon() }}
              </CRIcon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div v-if="displayMore" style="height: 22px">
      <v-menu bottom transition="slide-x-transition">
        <template #activator="{ on: { click } }">
          <CRIcon
            color="primary"
            class="cursor-pointer"
            :width="22"
            :height="22"
            view-box="0 0 24 24"
            @click.native="click"
          >
            more_vert
          </CRIcon>
        </template>
        <v-list>
          <v-list-tile
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
            :key="`action-${i}-${item.id ? item.id : ''}`"
          >
            <v-list-tile-title v-if="item.condition ? item.condition() : true">
              <a
                v-if="item.href"
                :id="`action-require-wrapper-${
                  item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
                }`"
                target="_blank"
                :href="item.href()"
                class="action-link"
              >
                <CRIcon
                  v-if="item.icon"
                  :key="`action-${i}-${item.icon}`"
                  :color="(item.color && item.color()) || 'primary'"
                >
                  {{ item.icon() }}
                </CRIcon>
                <span class="action-text">{{ item.title() }}</span>
              </a>

              <router-link
                v-if="item.to"
                :id="`action-require-wrapper-${
                  item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
                }`"
                target="_blank"
                :to="item.to"
                class="action-link"
              >
                <CRIcon
                  v-if="item.icon"
                  :color="(item.color && item.color()) || 'primary'"
                  :view-box="item.viewBox ? item.viewBox : '0 0 24 24'"
                >
                  {{ item.icon() }}
                </CRIcon>
                <span class="action-text">{{ item.title() }}</span>
              </router-link>

              <a v-if="item.click" class="action-link" @click="item.click">
                <CRIcon
                  v-if="item.icon"
                  :color="item.color ? item.color() : 'primary'"
                  :class="item.icon()"
                >
                  {{ item.icon() }}
                </CRIcon>
                <span class="action-text">{{ item.title() }}</span>
              </a>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => {
        return [
          // Examples!
          // {
          //   icon: () => 'remove_red_eye',
          //   title: () => 'View Customer Detail',
          //   quickAccess: true,
          //   path: () => {
          //     return `/customers/view/${this.row.customerId}`
          //   },
          // },
          // {
          //   icon: () => 'edit',
          //   title: () => 'Edit Customer',
          //   quickAccess: true,
          //   path: () => {
          //     return `/customers/edit/${this.row.customerId}`
          //   },
          // },
          // {
          //   icon: () => 'icon-trash',
          //   title: () => 'Delete Customer',
          //   quickAccess: true,
          //   click: () => {
          //     this.handleAction('delete_customer_detail', this.rowProps)
          //   },
          // },
        ]
      },
    },
  },
  computed: {
    displayMore() {
      return this.menu.size > 1
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 22px;
}

.v-datatable .v-icon {
  margin-right: 2px;
  font-size: 22px;

  &:last-child {
    margin-right: 0;
  }
}

.action-links a,
.action-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
}

.with-margin {
  margin-right: 2px;
}

.action-text {
  padding-left: 8px;
}
.action-svg {
  cursor: pointer;
}
</style>
