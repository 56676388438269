var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":""}},[_vm._l((_vm.menu
      .filter((m) => m.quickAccess)
      .filter((m) => (m.condition ? m.condition(_vm.row) : true))),function(item,idx){return _c('div',{key:`item-${idx}-${item && item.title() ? item.title() : ''}`,staticClass:"with-margin",staticStyle:{"max-height":"22px","margin-left":"10px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(action){return [_c('span',_vm._g({staticClass:"item-space",staticStyle:{"max-height":"22px"},on:{"click":function($event){$event.stopPropagation();return item.click.apply(null, arguments)}}},action.on),[(item.icon && item.click)?_c('CRIcon',{attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"color":(item.color && item.color()) || 'primary',"width":22,"height":22,"view-box":item.viewBox}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),(item.svg && item.click)?_c('img',{staticClass:"action-svg",attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"src":item.svg},on:{"click":item.click}}):_vm._e(),(item.to)?_c('router-link',{staticClass:"action-link",attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"to":item.to,"target":"_blank"}},[(item.icon)?_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox ? item.viewBox : '0 0 24 24',"width":24,"height":24}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e()],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title()))])])],1)}),(_vm.displayMore)?_c('div',{staticStyle:{"height":"22px"}},[_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: { click } }){return [_c('CRIcon',{staticClass:"cursor-pointer",attrs:{"color":"primary","width":22,"height":22,"view-box":"0 0 24 24"},nativeOn:{"click":function($event){return click.apply(null, arguments)}}},[_vm._v(" more_vert ")])]}}],null,false,3006284109)},[_c('v-list',_vm._l((_vm.menu.filter((m) =>
            m.condition ? m.condition() : true
          )),function(item,i){return _c('v-list-tile',{key:`action-${i}-${item.id ? item.id : ''}`},[(item.condition ? item.condition() : true)?_c('v-list-tile-title',[(item.href)?_c('a',{staticClass:"action-link",attrs:{"id":`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : _vm.idx
              }`,"target":"_blank","href":item.href()}},[(item.icon)?_c('CRIcon',{key:`action-${i}-${item.icon}`,attrs:{"color":(item.color && item.color()) || 'primary'}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),_c('span',{staticClass:"action-text"},[_vm._v(_vm._s(item.title()))])],1):_vm._e(),(item.to)?_c('router-link',{staticClass:"action-link",attrs:{"id":`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : _vm.idx
              }`,"target":"_blank","to":item.to}},[(item.icon)?_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox ? item.viewBox : '0 0 24 24'}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),_c('span',{staticClass:"action-text"},[_vm._v(_vm._s(item.title()))])],1):_vm._e(),(item.click)?_c('a',{staticClass:"action-link",on:{"click":item.click}},[(item.icon)?_c('CRIcon',{class:item.icon(),attrs:{"color":item.color ? item.color() : 'primary'}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),_c('span',{staticClass:"action-text"},[_vm._v(_vm._s(item.title()))])],1):_vm._e()],1):_vm._e()],1)}),1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }