import Vue from 'vue'
import {
  camelCaseToTitleCase,
  camelCaseToKebabCase,
  yToIe,
  splitAtCapitals,
} from './string'
import { buildReservationRoute } from '@/utils/reservationPathBuilder'
import { store } from '@/state/store'

const MenuDataMapper = function (
  collection,
  row,
  rowProps,
  handleAction,
  options,
  urlBase
) {
  const picks = {
    'calls-mark-returned': () => {
      return {
        icon: (() => () => 'missed_call')(),
        viewBox: '0 0 24 24',
        color: () => 'error',
        title: (() => () => 'Mark Call Returned')(),
        quickAccess: true,
        condition: (row) => row?.callStatus?.callStatusId === 1,
        click: (() => () => {
          handleAction('mark_call_returned', rowProps)
        })(),
      }
    },
    'call-returned': () => {
      return {
        icon: (() => () => 'returned_call')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'Call Returned')(),
        quickAccess: true,
        condition: (row) => row?.callStatus?.callStatusId === 3,
        click: (() => () => {
          return true
        })(),
      }
    },
    'call-comments': () => {
      return {
        icon: (() => () => 'note')(),
        title: (() => () => 'Call Comments')(),
        quickAccess: false,
        click: (() => () => {
          handleAction('call_comments', rowProps)
          return true
        })(),
      }
    },
    'call-audio': () => {
      return {
        icon: (() => () => 'play_arrow')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'Call Recording')(),
        quickAccess: true,
        condition: () => !!row.recordingUrl,
        click: (() => () => {
          handleAction('call_audio', rowProps)
          return true
        })(),
      }
    },
    'call-download': () => {
      return {
        icon: (() => () => 'download')(),
        title: (() => () => 'Download Call')(),
        condition: () =>
          !!row.recordingUrl &&
          store.getters['auth/hasPermission']('canDownloadCalls'),
        click: (() => async () => {
          await fetch(row.recordingUrl)
            .then((response) => response.blob())
            .then((blob) => {
              let blobUrl = window.URL.createObjectURL(blob)
              let a = document.createElement('a')
              a.download = `${row.callId}.mp3`
              a.href = blobUrl
              document.body.appendChild(a)
              a.click()
              a.remove()
            })
        })(),
      }
    },
    'view-bids': () => {
      if (row.bidCount && row.bidCount > 0) {
        return {
          icon: (() => () => 'book_it')(),
          title: (() => () => 'View Bids')(),
          quickAccess: true,
          to: { name: 'bids' },
        }
      }

      return null
    },
    'view-reservation': () => {
      if (row.quoteConverted) {
        return {
          icon: (() => () => 'ticket')(),
          viewBox: '0 0 32 32',
          title: (() => () => 'View Reservation')(),
          quickAccess: true,
          to: buildReservationRoute(row),
        }
      }

      return null
    },
    'view-quote': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'View Quotes')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'quotes.view', params: { id: row.quoteId } },
      }
    },
    'view-affiliate': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'View')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'quotes.view', params: { id: row.quoteId } },
      }
    },
    'view-affiliate-availability': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'View')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'quotes.view', params: { id: row.quoteId } },
      }
    },
    'view-event': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        title: (() => () => 'View Event')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'events.edit', params: { id: row.eventId } }, //this is intentionally edit, we don't have a view mode
      }
    },
    'view-known-address': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        title: (() => () => 'View Known Address')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'known-address.view', params: { id: row.knownAddressId } },
      }
    },
    'edit-known-address': () => {
      return {
        icon: (() => () => 'edit')(),
        title: (() => () => 'Edit Known Address')(),
        id: 'edit',
        quickAccess: true,
        to: { name: 'known-address.edit', params: { id: row.knownAddressId } },
      }
    },
    'view-market-rates': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        title: (() => () => 'View Market Rate')(),
        quickAccess: true,
        to: { name: 'market-rates.view', params: { id: row.marketId } },
      }
    },
    'edit-market-rates': () => {
      return {
        icon: (() => () => 'edit')(),
        title: (() => () => 'Edit Market Rate')(),
        quickAccess: true,
        to: { name: 'market-rates.edit', params: { id: row.marketId } },
      }
    },
    view: () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        title: (() => () =>
          `View ${camelCaseToTitleCase(collection)} Detail`)(),
        quickAccess: true,
        id: 'view',
        to: {
          name: `${camelCaseToKebabCase(
            yToIe(collection)
          ).toLowerCase()}s.view`,
          params: {
            id: row[`${collection}Id`]
              ? row[`${collection}Id`]
              : row.userId
              ? row.userId
              : row[`${splitAtCapitals(collection)[0]}Id`],
          },
        },
      }
    },
    edit: () => {
      return {
        icon: (() => () => 'edit')(),
        viewBox: '0 0 24 24',
        id: 'edit',
        title: (() => () => `Edit ${camelCaseToTitleCase(collection)}`)(),
        quickAccess: true,
        to: {
          name: `${camelCaseToKebabCase(
            yToIe(collection)
          ).toLowerCase()}s.edit`,
          params: {
            id: row[`${collection}Id`]
              ? row[`${collection}Id`]
              : row.userId
              ? row.userId
              : row[`${splitAtCapitals(collection)[0]}Id`],
          },
        },
      }
    },
    'edit-no-quick-view': () => {
      return {
        title: (() => () => `Edit ${camelCaseToTitleCase(collection)}`)(),
        id: 'edit-no-quick-view',
        icon: (() => () => 'edit')(),
        quickAccess: false,
        to: {
          name: `${camelCaseToKebabCase(
            yToIe(collection)
          ).toLowerCase()}s.edit`,
          params: {
            id: row[`${collection}Id`] ? row[`${collection}Id`] : row.userId,
          },
        },
      }
    },
    delete: () => {
      return {
        icon: (() => () => 'trash')(),
        id: 'delete',
        title: (() => () => `Delete ${camelCaseToTitleCase(collection)}`)(),
        quickAccess: true,
        click: (() => () => {
          handleAction(`delete_${collection}_detail`, rowProps)
        })(),
      }
    },
    'delete-no-quick-view': () => {
      return {
        title: (() => () => `Delete ${camelCaseToTitleCase(collection)}`)(),
        id: 'delete-no-quick-view',
        icon: (() => () => 'trash')(),
        quickAccess: false,
        click: (() => () => {
          handleAction(`delete_${collection}_detail`, rowProps)
        })(),
      }
    },
    'add-user': () => {
      return {
        icon: (() => () => 'drivers')(),
        viewBox: '0 0 32 32',
        title: (() => () => 'Add User')(),
        quickAccess: true,
        to: {
          name: 'users.add',
          query: { companyId: rowProps.item.companyId },
        },
      }
    },
    'edit-driver-time-off': () => {
      return {
        title: (() => () => 'Edit')(),
        quickAccess: true,
        click: (() => async () => {
          store.dispatch('app/openDialog', {
            component: () => import('@/components/DriverTimeOffForm.vue'),
            data: {
              timeOffFormData: await store.dispatch(
                'driverTimeOffRequests/getRequestById',
                row.driverTimeOffRequestId
              ),
              mode: 'edit',
            },
          })
        })(),
      }
    },
    'approve-driver-time-off': () => {
      return {
        title: (() => () => 'Approve')(),
        quickAccess: true,
        click: (() => () => {
          store.dispatch(
            'driverTimeOffRequests/approveRequest',
            row.driverTimeOffRequestId
          )
          store.dispatch('driverTimeOffRequests/causeRefresh')
          store.dispatch('app/showAlert', {
            message: 'Time off request approved.',
          })
        })(),
      }
    },
    'reject-driver-time-off': () => {
      return {
        title: (() => () => 'Reject')(),
        quickAccess: true,
        click: (() => () => {
          store.dispatch(
            'driverTimeOffRequests/rejectRequest',
            row.driverTimeOffRequestId
          )
          store.dispatch('driverTimeOffRequests/causeRefresh')
          store.dispatch('app/showAlert', {
            message: 'Time off request rejected.',
          })
        })(),
      }
    },
    'delete-driver-time-off': () => {
      return {
        title: (() => () => 'Delete')(),
        quickAccess: true,
        click: (() => async () => {
          store.dispatch('app/openDialog', {
            component: () => import('@/components/DriverTimeOffForm.vue'),
            data: {
              timeOffFormData: await store.dispatch(
                'driverTimeOffRequests/getRequestById',
                row.driverTimeOffRequestId
              ),
              mode: 'delete',
            },
          })
        })(),
      }
    },
    'remove-from-parent': () => {
      return {
        title: (() => () => 'Remove from Parent')(),
        quickAccess: false,
        icon: (() => () => 'clear')(),
        click: (() => async () => {
          store.dispatch('app/openDialog', {
            component: () =>
              import('@/components/RemoveAccountFromParentForm.vue'),
            data: {
              enterpriseAccount: row.enterpriseAccount,
              teamAccount: row,
            },
          })
        })(),
      }
    },
    'edit-customer': () => {
      return {
        title: (() => () => `Edit ${camelCaseToTitleCase(collection)}`)(),
        icon: (() => () => 'edit')(),
        id: 'edit-customer',
        quickAccess: false,
        to: {
          name: 'customers.details',
          params: {
            id: row[`${collection}Id`] ? row[`${collection}Id`] : row.userId,
          },
        },
      }
    },
    'view-customer': () => {
      return {
        title: (() => () => `View ${camelCaseToTitleCase(collection)}`)(),
        icon: (() => () => 'remove_red_eye')(),
        id: 'view',
        quickAccess: true,
        to: {
          name: 'customers.details',
          params: {
            id: row[`${collection}Id`] ? row[`${collection}Id`] : row.userId,
          },
        },
      }
    },
    'delete-driver': () => {
      return {
        icon: () => 'trash',
        id: 'delete',
        title: () => 'Delete Driver',
        quickAccess: true,
        click: (() => () => {
          handleAction(`delete_driver_detail`, rowProps)
        })(),
      }
    },
    'void-refund': () => {
      return {
        id: 'void-refund',
        title: () => 'Void Refund',
        quickAccess: false,
        click: (() => () => {
          handleAction('VOID_REFUND', rowProps)
        })(),
      }
    },
    'delete-market': () => {
      return {
        icon: (() => () => 'trash')(),
        id: 'delete',
        title: (() => () => `Delete ${camelCaseToTitleCase(collection)}`)(),
        quickAccess: true,
        click: (() => () => {
          handleAction(`delete_market_detail`, rowProps)
        })(),
      }
    },
    'view-company-terms': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        title: (() => () => 'View Company Terms')(),
        id: 'view',
        quickAccess: true,
        to: { name: 'company-terms.view', params: { id: row.companyTermsId } },
      }
    },
    'edit-company-terms': () => {
      return {
        title: (() => () => `Edit Company Term`)(),
        id: 'edit-companyTerms',
        icon: (() => () => 'edit')(),
        quickAccess: true,
        to: {
          name: `company-terms.edit`,
          params: { id: row.companyTermsId },
        },
      }
    },
    'view-checkout-pages': () => {
      return {
        title: (() => () => `View Checkout Page`)(),
        id: 'view-checkout-page',
        icon: (() => () => 'remove_red_eye')(),
        quickAccess: true,
        to: {
          name: `checkout-pages.view`,
          params: { id: row.companyCheckoutPageId },
        },
      }
    },
    'edit-checkout-pages': () => {
      return {
        title: (() => () => `Edit Checkout Page`)(),
        id: 'edit-checkout-page',
        icon: (() => () => 'edit')(),
        quickAccess: true,
        to: {
          name: `checkout-pages.edit`,
          params: { id: row.companyCheckoutPageId },
        },
      }
    },
    'activate-driver': () => {
      if (row.driverActivated !== null && !row.driverActivated) {
        return {
          title: (() => () => `Activate Driver`)(),
          id: 'activate-driver',
          icon: (() => () => 'drivers')(),
          quickAccess: true,
          click: (() => () => {
            handleAction(`activate_driver`, rowProps)
          })(),
        }
      }
    },
    'deactivate-driver': () => {
      if (row.driverActivated !== null && row.driverActivated) {
        return {
          title: (() => () => `Deactivate Driver`)(),
          id: 'deactivate-driver',
          icon: (() => () => 'deactivate')(),
          viewBox: '0 0 24 24',
          quickAccess: true,
          click: (() => () => {
            handleAction(`deactivate_driver`, rowProps)
          })(),
        }
      }
    },
    'view-affiliate-driver-sidebar': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        quickAccess: true,
        title: (() => () => 'View Affiliate Driver Sidebar')(),
        click: (() => async () => {
          const component = () =>
            import('@/components/AffiliateDriverSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              title: row.name,
              driverId: row.driverId,
            },
            component,
          })
        })(),
      }
    },
    'edit-affiliate-vehicle-sidebar': () => {
      return {
        icon:(() => () => 'edit')(),
        viewBox: '0 0 24 24',
        quickAccess: true,
        title: (() => () => 'Edit Affiliate Vehicle Sidebar')(),
        click: (() => async () => {
          const component = () =>
            import('@/views/VehiclesForm.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              mode: 'edit',
              title: 'Edit Vehicle',
              id: row.vehicleId,
              companyId: row.companyId,
            },
            component,
          })
        })()
      }
    },
    'view-affiliate-vehicle-sidebar': () => {
      return {
        icon: (() => () => 'remove_red_eye')(),
        viewBox: '0 0 24 24',
        quickAccess: true,
        title: (() => () => 'View Affiliate Vehicle Sidebar')(),
        click: (() => async () => {
          const component = () =>
            import('@/components/AffiliateVehicleSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              title: row.vehicleName,
              vehicleId: row.vehicleId,
              deviceInfo: row.deviceInfo,
              companyId: row.companyId,
              licensePlate: row.licensePlate,
              vehicleName: row.vehicleName,
              vinNumber: row.vinNumber,
            },
            component,
          })
        })(),
      }
    },
    'view-chat-sidebar': () => {
      return {
        icon: (() => () => 'chat')(),
        viewBox: '0 0 24 24',
        color: () => 'primary',
        title: (() => () => 'View Chat Transcript')(),
        quickAccess: true,
        click: (() => async () => {
          const component = () =>
            import('@/components/ChatTranscriptForm.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              title: `Chat ${row.chatId}`,
              chatId: row.chatId,
              chatFrom: row.chatFrom,
              chatTo: row.chatTo,
              answeredByName: row.answeredByName
            },
            component,
          })
        })(),
      }
    },
  }

  return options
    .map((item) => {
      return picks[item]()
    })
    .filter(Boolean)
}
// collectionSingular
export default function (
  component,
  collectionSingular,
  options = ['view', 'edit', 'delete'],
  urlBase = ''
) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(
            collectionSingular,
            this.row,
            this.rowProps,
            this.handleAction,
            options,
            urlBase
          ),
        },
      })
    },
  })
}
